import crypto from 'crypto';
import SparkMD5 from 'spark-md5';
import { getOssPreviewLink, getResourceSign, uploadOss } from '@/api/common';

async function getFileMd5(file: File) {
  return new Promise((resolve, reject) => {
    // 分片读取文件
    const fileReader = new FileReader();
    const chunkSize = 1024 * 1024 * 2; // 每个分片的大小 2MB
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const loadFile = (e: ProgressEvent<FileReader>) => {
      spark.append(e.target?.result as ArrayBuffer);
      currentChunk++;
      if (currentChunk < chunks) {
        // 未读取完继续读取
        loadNext();
      } else {
        // 文件读取完
        const md5 = spark.end(); // 获取文件的md5值
        resolve(md5);
      }
    };
    const loadError = () => {
      // 读取文件失败
      reject(new Error('compute file md5 error'));
    };
    const loadNext = () => {
      fileReader.onload = loadFile;
      fileReader.onerror = loadError;
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      const data = file.slice(start, end);
      fileReader.readAsArrayBuffer(data);
    };
    loadNext();
  });
}

// 获取当前时间戳
const timestamp = Date.now();

// 生成9位的16进制字符串
const generateRandomHex = () => crypto.randomBytes(5).toString('hex').substring(0, 9);

function getFileType(type: string, extend: string) {
  const fileArr = [
    { content: 'image/', type: 'imgs' },
    { content: 'text', type: 'text' },
    { content: 'video/', type: 'video' }
    // { content: '/pdf', type: 'pdf' },
    // { content: 'richText', type: 'text' },
    // { content: 'office', type: 'office' },
  ];
  let result = extend;
  fileArr.forEach((value) => {
    if (type.includes(value.content)) {
      result = value.type;
    }
  });
  return result;
}

export const customRequest = async (options: any) => {
  const { file } = options;
  // 默认扩展名
  let extendType = 'png';
  const fileType = file.type;
  const tempArr = file.name.split('.');
  if (tempArr.length > 0) {
    extendType = tempArr[tempArr.length - 1];
  }

  try {
    const md5 = await getFileMd5(file);
    const dataToHash = `${md5}${timestamp}${generateRandomHex()}`;
    // 使用SHA-256进行哈希计算
    const hash = crypto.createHash('sha256').update(dataToHash).digest('hex');

    // 缩短哈希字符串到18位
    const shortenedHash = hash.substring(0, 18);

    const res: any = await getResourceSign({
      resourceUrl: `${options?.resourceDir ?? 'commonUpload'}/${shortenedHash}.${extendType}`,
      isPublic: options?.isPublic ?? true,
      fileType: getFileType(fileType, extendType)
    });

    if (res) {
      const { dir, policy, accessId, signature, host } = res.data;
      const key = `${dir}`;
      const formData = new FormData();
      formData.append('key', key);
      formData.append('content-type', fileType);
      formData.append('policy', policy);
      formData.append('AccessKeyId', accessId);
      formData.append('signature', signature);
      formData.append('file', file);

      await uploadOss({
        url: host,
        formData
      });

      const linkData = await getOssPreviewLink({
        fileKey: key,
        downloadExpireTime: Math.min(options.expireTime ?? 60 * 1000, 60 * 1000),
        isPublic: options.isPublic ?? true
      });

      return linkData.data;
      // options?.onSuccess();
      // options?.custonSuccess({
      //   name: file.name,
      //   url: linkData
      // });
    }
  } catch (error) {
    options?.onError({
      error
    });
  }
};
